import PropTypes from 'prop-types'
import ModalMUI from '@mui/material/Modal'
import styled from 'styled-components'
import Title from '../../atoms/Typography/Title'
import Button from '@mui/material/IconButton'
import close from '../../../assets/icon/x.svg'
import Tooltip from '../../atoms/ToolTip/ToolTip'
import AlertModal from '../../atoms/Icon/AlertModal'
import { useEffect } from 'react'

const Center = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalBox = styled(ModalMUI)`
  z-index: 1500 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: none;
  width: auto;
  max-width: 550px;
  margin: auto;
  padding: 10px;
  @media (max-width: 700px) {
    margin: 1%;
    height: 100%;
    width: 100%;
  }
`
const ModalTitle = styled(Title)`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.palette.text.primary};
  width: 70%;
`

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
`

const ContainerHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`

const TooltipFront = styled(Tooltip)`
  z-index: 9999 !important;
`

const ModalContainer = styled('div')`
  background-color: ${({ theme }) => theme.palette.background.default};
  box-shadow: 0px 0px 3.12996px 1.04332px rgba(0, 0, 0, 0.25);
  border-radius: 8.26805px;
  position: relative;
  padding: 20px 45px 40px 45px;
`

const CancelButton = styled(Button)`
  background-color: #f6f6f7;
  font-size: 14px;
  border: solid 1px #6b6b6b;
  padding: 10px 20px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DeleteButton = styled(Button)`
  background-color: #008060;
  font-size: 14px;
  color: white;
  padding: 10px 20px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CloseBtn = styled(Button)`
  position: absolute;
  top: 22px;
  right: 22px;
`

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-right: 30px;
`

const WarningIcon = styled('div')`
  margin-right: 30px;
  width: 65px;
  height: 65px;
`

const Modal = ({ open, onClose, title, onSubmit, documentName }) => {
  useEffect(() => {
    // Función que maneja el evento de la tecla "Esc"
    const handleEsc = event => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    // Agregar el event listener solo si el modal está abierto
    if (open) {
      window.addEventListener('keydown', handleEsc)
    }

    // Limpiar el event listener cuando se cierre el modal
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [open, onClose])
  return (
    <Center>
      <ModalBox
        disableAutoFocus
        disableEnforceFocus
        open={open}
        onClose={onClose}
        aria-labelledby={title}
      >
        <ModalContainer>
          <CloseBtn onClick={onClose}>
            <img src={close} alt="close" height={15} />
          </CloseBtn>
          <Container>
            <ContainerHeader>
              <WarningIcon>
                <AlertModal />
              </WarningIcon>
              <ModalTitle>
                ¿ Estás seguro que deseas eliminar el documento{' '}
                {documentName} ?
              </ModalTitle>
            </ContainerHeader>
          </Container>
          <ButtonContainer>
            <TooltipFront title="Cancelar" placement="bottom">
              <CancelButton onClick={onClose}>Cancelar</CancelButton>
            </TooltipFront>
            <TooltipFront title="Eliminar" placement="bottom">
              <DeleteButton onClick={onSubmit}>Eliminar</DeleteButton>
            </TooltipFront>
          </ButtonContainer>
        </ModalContainer>
      </ModalBox>
    </Center>
  )
}
Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  documentName: PropTypes.string
}
Modal.defaultProps = {
  open: false
}
export default Modal
