import isArray from 'lodash/isArray'
import deburr from 'lodash/deburr'
import round from '../../utils/round'
import dateDetailOrder from '../../adapters/days/dateDetailOrder'
import tableDateFormat from '../../adapters/days/tableDateFormat'
import normalizeOrderDelivery from '../orderBilling/normalizeOrderDelivery'
import normalizeOrderBilling from '../orderBilling/normalizeOrderBilling'
import capitalizeName from '../../utils/capitalizeName'

const getBillingInfo = (
  billing,
  companyName,
  companyGiro,
  companyRut,
  customer
) => ({
  billing: {
    billing_address1: capitalizeName(billing?.billingAddress1),
    billing_address2: capitalizeName(billing?.billingAddress2),
    billing_county: capitalizeName(billing?.billingCounty),
    billing_region: capitalizeName(billing?.billingRegion)
  },
  company_name: capitalizeName(companyName),
  company_giro: capitalizeName(companyGiro),
  company_rut: companyRut,
  customer: {
    customer_email: customer?.customerEmail
  }
})

const getDeliveryInfo = (destination, customer) => ({
  destination: {
    destination_county: capitalizeName(destination?.destinationCounty),
    destination_region: capitalizeName(destination?.destinationRegion),
    destination_address1: capitalizeName(destination?.destinationAddress1),
    destination_address2: capitalizeName(destination?.destinationAddress2)
  },
  customer: {
    customer_name: capitalizeName(customer?.customerName),
    customer_rut: customer?.customerRut
  }
})

const normalizeOrder = ({
  commission,
  payout,
  payoutDate,
  seller,
  order,
  totalSale,
  dispatchedSale,
  accountingStatement,
  id,
  shopifyId,
  shopifyDate,
  brandDetail,
  status,
  type,
  otherDiscount,
  parentId,
  transport,
  penalty,
  items,
  reason,
  billing,
  documentType,
  statusBilling,
  destination,
  companyName,
  companyGiro,
  companyRut,
  customer,
  documentName
}) => {
  const orderDetails = {
    id,
    documentName: documentName || undefined,
    order,
    createdAt: shopifyDate,
    transport,
    penalty,
    date: tableDateFormat(shopifyDate),
    idShopify: shopifyId || order?.name,
    idOrder: id,
    sellerName: seller,
    isDispatched: status,
    status,
    type,
    otherDiscount:
      otherDiscount === 0 ? transport + penalty : round(otherDiscount),
    totalSale: round(totalSale),
    dispatchedSale: round(dispatchedSale),
    payout: round(payout),
    payoutDate: payoutDate ? tableDateFormat(payoutDate) : 'No Aplica',
    commission: round(commission),
    accountingStatement,
    detail: seller,
    brandName: isArray(brandDetail) ? brandDetail[0]?.brand : '',
    parentId,
    items,
    itemList: items,
    reason,
    billing: normalizeOrderBilling(
      getBillingInfo(
        billing,
        companyName,
        companyGiro,
        companyRut,
        customer
      )
    ),
    documentType,
    delivery: normalizeOrderDelivery(
      getDeliveryInfo(destination, customer)
    ),
    statusBilling
  }

  const orderSummary = {
    id,
    type,
    status,
    parentId,
    transport,
    penalty,
    order,
    items,
    itemList: items,
    createdAt: dateDetailOrder(shopifyDate),
    date: tableDateFormat(shopifyDate),
    idShopify: order?.name,
    idOrder: id,
    sellerName: seller,
    isDispatched: status,
    totalSale: round(totalSale),
    otherDiscount:
      otherDiscount === 0 ? transport + penalty : round(otherDiscount),
    dispatchedSale: round(dispatchedSale),
    payout: round(payout),
    payoutDate: tableDateFormat(payoutDate),
    commission: round(commission),
    accountingStatement,
    brandName: isArray(brandDetail) ? brandDetail[0]?.brand : ''
  }

  const searchParams = new URLSearchParams({
    id: id,
    type: deburr(type).toLowerCase(),
    status: deburr(status).toLowerCase()
  }).toString()

  return {
    ...orderDetails,
    ...orderSummary,
    to: {
      pathname: `/pedidos/${shopifyId === parentId ? id : parentId}`,
      state: {
        title: id,
        brand: `Tipo: ${type}`,
        status,
        featuredImage: { url: items[0]?.image },
        ...orderDetails,
        ...orderSummary
      },
      search: `${shopifyId === parentId ? '' : `${searchParams}`}`
    }
  }
}

export default normalizeOrder
