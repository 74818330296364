import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'

import ProductInfoForm from '../../organisms/ProductInfoForm/ProductInfoForm'
import ProductPriceInventoryForm from '../../organisms/ProductPriceInventoryForm/ProductPriceInventoryForm'
import ProductDetailsForm from '../../organisms/ProductDetailsForm/ProductDetailsForm'
import ProductVariantForm from '../../organisms/ProductVariantForm/ProductVariantForm'
import ProductExtraOptionsForm from '../../organisms/ProductExtraOptionsForm/ProductExtraOptionsForm'
import InputSelect from '../../atoms/InputSelect'
import ButtonPreviewProduct from '../../molecules/ButtonPreviewProduct/ButtonPreviewProduct'
import ButtonBack from '../../atoms/ButtonBack/ButtonBack'

const Row = styled('div')`
  display: flex;
  flex-direction: 'row';
  align-items: baseline;
`

const RowCenter = styled('div')`
  display: flex;
  flex-direction: 'row';
  align-items: center;
`

const Spinner = styled(CircularProgress)`
  color: #f6f6f7;
`

const Form = styled('form')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const ButtonSendStyled = styled(Button)`
  height: 49px;
  width: 213.93275451660156px;
  border-radius: 5px;
  margin-left: 1%;
  @media (max-width: 1100px) {
    margin-left: 0%;
  }
  .MuiButtonBase-root:disabled {
    cursor: ${({ isloading }) =>
      isloading === 'true' ? 'wait' : 'not-allowed'};
    pointer-events: auto;
  }
`

const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;
  margin-bottom: 5%;
  .MuiButtonBase-root:disabled {
    cursor: ${({ isloading }) =>
      isloading === 'true' ? 'wait' : 'not-allowed'};
    pointer-events: auto;
  }
`

const Head = styled('div')`
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  @media (max-width: 1100px) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-left: 3%;
  }
`

const StatusContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 1100px) {
    margin: 0px;
    margin-bottom: 5%;
    justify-content: center;
    align-items: center;
  }
`

const InputSelectStyled = styled(InputSelect)`
  background-color: #ffffff;
  height: 36px;
  width: 254px;
  border-radius: 8px;
  border: none;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  outline: none;
`

const ProductName = styled('h1')`
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
  text-transform: none;
  text-align: left;
  margin-left: 1rem;
  @media (max-width: 1100px) {
    margin-bottom: 1.5rem;
  }
`

const ProductsTemplate = ({
  errors,
  isSubmitting,
  control,
  register,
  statusOnChange,
  allFiles,
  setAllFiles,
  productName,
  isDetailsView,
  productStatus,
  option,
  mediaList,
  variants,
  onBack,
  onSubmit,
  setValue,
  clearErrors,
  setError,
  productId,
  to
}) => {
  return (
    <Form onSubmit={onSubmit}>
      <Head>
        <Row>
          <ButtonBack
            onClick={onBack}
            color="inherit"
            isloading={String(isSubmitting)}
          />
          <ProductName>
            {isDetailsView ? productName : 'Agregar producto'}{' '}
          </ProductName>
        </Row>
        {isDetailsView ? (
          <RowCenter>
            <StatusContainer>
              <InputSelectStyled
                value={
                  String(productStatus) === 'Deshabilitar'
                    ? 'Deshabilitado'
                    : productStatus
                }
                errors={{}}
                name="status"
                onChange={statusOnChange}
                isloading={String(isSubmitting)}
              >
                <MenuItem key={String(option)} value={option}>
                  {option}
                </MenuItem>
              </InputSelectStyled>
            </StatusContainer>
            {productName && to && (
              <ButtonPreviewProduct
                to={to}
                titleTooltip="Vista previa pagina web"
                isloading={String(isSubmitting)}
              />
            )}
          </RowCenter>
        ) : null}
      </Head>

      <ProductInfoForm
        setError={setError}
        clearErrors={clearErrors}
        errors={errors}
        control={control}
        mediaList={mediaList}
        isDetailsView={isDetailsView}
        productId={productId}
      />
      <ProductPriceInventoryForm
        control={control}
        setValue={setValue}
        clearErrors={clearErrors}
      />
      <ProductDetailsForm control={control} errors={errors} />
      <ProductVariantForm
        isDetailsView={isDetailsView}
        control={control}
        errors={errors}
        variants={variants}
      />
      <ProductExtraOptionsForm
        control={control}
        register={register}
        errors={errors}
        allFiles={allFiles}
        setAllFiles={setAllFiles}
        setValue={setValue}
        clearErrors={clearErrors}
      />
      {isDetailsView ? null : (
        <ButtonContainer isloading={String(isSubmitting)}>
          <ButtonSendStyled
            onClick={onBack}
            variant="contained"
            color="third"
            disabled={isSubmitting}
          >
            Cancelar
          </ButtonSendStyled>
          <ButtonSendStyled
            onClick={onSubmit}
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            isloading={String(isSubmitting)}
            endIcon={isSubmitting ? <Spinner size="1.2rem" /> : null}
            color={!isEmpty(errors?.media) ? 'error' : 'primary'}
          >
            Agregar producto
          </ButtonSendStyled>
        </ButtonContainer>
      )}
    </Form>
  )
}

ProductsTemplate.propTypes = {
  errors: PropTypes.any,
  isSubmitting: PropTypes.bool,
  control: PropTypes.any,
  categoryList: PropTypes.array,
  brandList: PropTypes.array,
  setAllFiles: PropTypes.func,
  allFiles: PropTypes.object,
  productName: PropTypes.string,
  isDetailsView: PropTypes.bool,
  productStatus: PropTypes.string,
  productId: PropTypes.string,
  option: PropTypes.string,
  mediaList: PropTypes.array,
  statusOnChange: PropTypes.func,
  variants: PropTypes.array,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
  register: PropTypes.any,
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
  setError: PropTypes.func,
  to: PropTypes.string
}
ProductsTemplate.defaultProps = {
  isSubmitting: false,
  isDetailsView: false,
  mediaList: [],
  variants: [],
  allFiles: {},
  productName: '',
  onBack: undefined,
  onSubmit: undefined,
  to: undefined
}
export default ProductsTemplate
