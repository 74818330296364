import { useLocation } from 'react-router-dom'

import styled from 'styled-components'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Button from '@mui/material/Button'
import Title from '../../atoms/Typography/Title'
import Text from '../../atoms/Typography/Text'
import Navigate from '../../atoms/Navigate/Navigate'
import Logo from '../../../assets/logoWeb.webp'
import ArrowBackIosNewOutlined from '@mui/icons-material/ArrowBackIosOutlined'
import SubtitleCard from '../../atoms/Typography/SubtitleCard'

const Container = styled('div')`
  height: 100vh;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: row;
`

const Column = styled(Container)`
  flex-direction: column;
`
const ColumnLeft = styled(Column)`
  background-color: ${({ theme }) => theme.palette.background.paper};
`

const Img = styled('img')`
  height: 171px;
  width: 180px;

  @media (max-width: 800px) {
    margin-bottom: 10px;
    height: 110px;
    width: 120px;
  }
`
const TitleLogo = styled(Title)`
  font-size: 24px;
  font-style: bold;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: center;

  @media (max-width: 800px) {
    align-self: center;
    padding-left: 0;
    margin-bottom: 1%;
  }
`
const TitleKC = styled(Title)`
  font-size: 24px;
  font-style: bold;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: center;
  color: ${({ theme }) => theme.palette.third.light};
  margin-bottom: 3%;
  @media (max-width: 800px) {
    align-self: center;
    padding-left: 0;
    margin-bottom: 1%;
  }
`
const Subtitle = styled(SubtitleCard)`
  font-size: 18px;
  font-style: bold;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-align: center;

  @media (max-width: 800px) {
    align-self: center;
    padding-left: 0;
    margin-bottom: 1%;
  }
`
const IconError = styled(ErrorOutline)`
  font-size: 60px;
  color: ${({ theme }) => theme.palette.error.dark};
  display: flex;
  flex-direction: row;
`
const Divider = styled('div')`
  margin-top: 1rem;
`
Divider.displayName = 'Divider'

const NotFound = () => {
  const { pathname, state } = useLocation()

  return (
    <Container>
      <ColumnLeft>
        <TitleKC variant="h3">Seller Center</TitleKC>{' '}
        <Img alt="logo" src={Logo} />
      </ColumnLeft>
      <Column>
        <IconError />

        <TitleLogo variant="h1" aria-label="No tiene permisos">
          Error 404
        </TitleLogo>
        <Subtitle variant="h2" aria-label="No tiene permisos">
          Pagina no encontrada
        </Subtitle>
        <Divider />
        <Text>
          La ruta actual <b>{pathname}</b> no se existe en la aplicación
        </Text>

        <Divider />
        <Navigate
          data-testid="Link_error404--back"
          to="/pedidos"
          state={state}
        >
          <Button
            variant="contained"
            endIcon={<ArrowBackIosNewOutlined />}
          >
            Volver a la pagina de pedidos
          </Button>
        </Navigate>
      </Column>
    </Container>
  )
}

export default NotFound
