import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import Checkbox from '../Checkbox'
import ModalImage from '../../molecules/ModalImage/ModalImage'
import { useState } from 'react'
import expand from '../../../assets/icon/expand.svg'

const CheckboxImageContainer = styled('div')`
  vertical-align: flex-end;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  width: ${({ size }) => (size === 'large' ? '80px' : '76px')};
  height: ${({ size }) => (size === 'large' ? '90px' : '76px')};
  margin-right: ${({ size }) => (size === 'large' ? '1rem' : '0px')};
  transition: all 0.3s ease;

  &:hover {
    scale: 1.15;
  }
`
const ImageBackground = styled('img')`
  border-radius: 10px;
  position: absolute;
  object-fit: fill;
  ${({ iserror }) =>
    iserror === 'true'
      ? css`
          filter: grayscale(1);
        `
      : null};
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
        `
      : null};
  width: ${({ size }) => (size === 'large' ? '92px' : '77px')};
  height: ${({ size }) => (size === 'large' ? '92px' : '78px')};
  cursor: ${({ isVariantModalOpened, isDetailsView }) =>
    isVariantModalOpened || !isDetailsView ? 'default' : 'pointer'};
  transition: all 0.2s ease;
  &:hover + .hover-image {
    visibility: visible;
    scale: 1.05;
  }
`

const HoverImage = styled('img')`
  width: 25px;
  z-index: 10;
  position: relative;
  left: 35%;
  bottom: 10%;
  visibility: hidden;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    visibility: visible;
    cursor: zoom-in;
  }
`

const CheckboxBackground = styled(Checkbox)`
  position: relative;
  background-color: white;
  bottom: ${({ size }) => (size === 'large' ? '20px' : '13px')};
  left: 5px;
  z-index: 2;
  &:hover {
    background-color: white;
  }
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
          color: #d1d0d0;
        `
      : null};
  ${({ iserror }) =>
    iserror === 'true'
      ? css`
          color: #e72020;
        `
      : null};
`

const CheckboxImage = ({
  checked,
  onChange,
  src,
  alt,
  errors,
  disabled,
  size,
  isDetailsView,
  control,
  isVariantModalOpened = false,
  index,
  setMediaList,
  appendToDelete,
  media,
  ...props
}) => {
  const iserror =
    errors?.media && String(!isEmpty(errors?.media[props?.index]))
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [show, toggle] = useState(false)
  return (
    <>
      {' '}
      <CheckboxImageContainer size={size}>
        <CheckboxBackground
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          iserror={iserror}
          {...props}
        />
        <ImageBackground
          src={src}
          alt={alt}
          aria-label={alt}
          aria-labelledby="t2"
          title={alt}
          size={size}
          iserror={iserror || String(disabled)}
          disabled={disabled}
          onClick={() =>
            (!isVariantModalOpened || isDetailsView) & setIsOpenModal(true)
          }
          isDetailsView={isDetailsView}
          isVariantModalOpened={isVariantModalOpened}
          onMouseEnter={() => toggle(true)}
          onMouseLeave={() => toggle(false)}
          style={{ filter: show ? 'brightness(70%)' : 'brightness(100%)' }}
        />
        <HoverImage
          src={expand}
          className="hover-image"
          onClick={() =>
            (!isVariantModalOpened || isDetailsView) & setIsOpenModal(true)
          }
          onMouseEnter={() => toggle(true)}
          onMouseLeave={() => toggle(false)}
        />
      </CheckboxImageContainer>
      {isVariantModalOpened || !isDetailsView ? null : (
        <ModalImage
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          title={alt}
          src={src}
          control={control}
          index={index}
          appendToDelete={appendToDelete}
          setMediaList={setMediaList}
          media={media}
        />
      )}
    </>
  )
}

CheckboxImage.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string,
  error: PropTypes.object,
  errors: PropTypes.object,
  index: PropTypes.number,
  size: PropTypes.string,
  isDetailsView: PropTypes.bool,
  control: PropTypes.any,
  isVariantModalOpened: PropTypes.bool,
  setMediaList: PropTypes.func,
  appendToDelete: PropTypes.any,
  media: PropTypes.any
}
CheckboxImage.defaultProps = {
  size: 'small',
  media: [],
  setMediaList: data => data
}
export default CheckboxImage
